.modal-swiper {

    .image-carousel-next, .image-carousel-prev {
        height: 2rem;
        width: 2rem;
        position: absolute;
        top: 50%;
        cursor: pointer;
        z-index: 99;

        &.swiper-button-disabled {
            circle {
                fill: #409fd8;
            }
        }
    }
    .image-carousel-next {
        right: 0;
    }

    .image-carousel-prev {
        left: 0;
    }

    .image-carousel-pagination {
        font-family: "Poppins";

        .swiper-pagination-current {
            font-weight: bold;
        }
    }
}
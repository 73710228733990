.Input {
  width: 100%;
}

.TextAreaInput {
  width: 100%;
  height: 50px;
  margin-bottom: 6px;
  margin-top: 6px;
  border: 1px solid #c0d4db;
  border-radius: 2px;
  font-family: "Assistant";
  text-align: left;
  padding: 13px;
  transition: height .3s ease;
  &:active, &:focus {
    height: 100px;
  }
}

.InputDetails {
  width: 180px;
  margin-top: -10px;
  height: 50px;
  border: 1px solid #c0d4db;
  border-radius: 2px;
  font-family: "Asssitant";
  text-align: left;
  padding: 10px;
}

.InputError {
  width: 100%;
  height: 50px;
  margin-bottom: 6px;
  margin-top: 6px;
  border: 1px solid #d53f8c;
  border-radius: 2px;
  font-family: "Assistant";
  text-align: left;
  padding: 13px;
}

.InputDrawer {
  width: 450px;
}

.InputDrawerAdress,
.InputDrawerState {
  width: 110px;
  margin-left: 10px;
}

.InputDrawerState {
  margin-top: 6px;
}

.Input,
.InputDrawer,
.InputDrawerAdress,
.InputDrawerState {
  height: 50px;
  margin-bottom: 6px;
  margin-top: 6px;
  border: 1px solid #c0d4db;
  border-radius: 2px;
  font-family: "Assistant";
  text-align: left;
  padding: 13px;
}

.Input::placeholder,
.InputDrawer::placeholder,
.InputDrawerAdress::placeholder,
.InputDrawerState::placeholder,
.TextAreaInput::placeholder {
  color: #464646;
  font-weight: normal;
  font-family: "Assistant";
  text-align: left;
}

.Checkbox {
  cursor: pointer;
}

.InputDrawerGridItem {
  height: 50px;
  margin-bottom: 6px;
  margin-top: 6px;
  border: 1px solid #c0d4db;
  border-radius: 2px;
  font-family: "Assistant";
  text-align: left;
  padding: 13px;
  width: 100%;
}

.InputDrawerGridItemSelect {
  cursor: pointer;
}
